type StorageType = "session" | "local";
type UseStorageReturnValue = {
  getSessionItem: (key: string) => string;
  setSessionItem: (key: string, value: string) => boolean;
  removeSessionItem: (key: string) => void;
  getLocalItem: (key: string) => string;
  setLocalItem: (key: string, value: string) => boolean;
  removeLocalItem: (key: string) => void;
};

const useStorage = (): UseStorageReturnValue => {
  const storageType = (type?: StorageType): "localStorage" | "sessionStorage" =>
    `${type ?? "session"}Storage`;

  const isBrowser: boolean = ((): boolean => typeof window !== "undefined")();

  const getItem = (key: string, type?: StorageType): string => {
    return isBrowser ? window[storageType(type)][key] : "";
  };

  const setItem = (key: string, value: string, type?: StorageType): boolean => {
    if (isBrowser) {
      window[storageType(type)].setItem(key, value);
      return true;
    }

    return false;
  };

  const removeItem = (key: string, type?: StorageType): void => {
    if (isBrowser) {
      window[storageType(type)].removeItem(key);
    }
  };

  const getSessionItem = (key: string): string => {
    return getItem(key, "session");
  };

  const setSessionItem = (key: string, value: string): boolean => {
    return setItem(key, value, "session");
  };

  const removeSessionItem = (key: string): void => {
    removeItem(key, "session");
  };
  const getLocalItem = (key: string): string => {
    return getItem(key, "local");
  };

  const setLocalItem = (key: string, value: string): boolean => {
    return setItem(key, value, "local");
  };

  const removeLocalItem = (key: string): void => {
    removeItem(key, "local");
  };

  return {
    getSessionItem,
    setSessionItem,
    removeSessionItem,
    getLocalItem,
    setLocalItem,
    removeLocalItem,
  };
};

export default useStorage;
