import "@/styles/globals.scss";
import type { AppProps } from "next/app";
import Head from "next/head";
import React, { useEffect } from "react";
import layout from "@/styles/layout.module.scss";
import "@/styles/custom-react-grid-layout.css";
import BaseProvider from "@/contexts/BaseContext";
import DescriptionProvider from "@/contexts/DescriptionContext";
import { AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import useStorage from "@/hooks/useStorage";
import "../styles/lp/lp_common.scss";
import "../styles/lp/lp_default.scss";
import "../styles/lp/jp_additional.scss";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { setSessionItem } = useStorage();

  useEffect(() => {
    const currentURL = router.asPath;

    if (currentURL.includes("/login") || currentURL.includes("/signup")) {
      setSessionItem("lastVisited", currentURL);
    }

    const handleRouteChange = (url: string) => {
      if (url.includes("/login") || url.includes("/signup")) {
        setSessionItem("lastVisited", url);
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, setSessionItem]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (typeof window.gtag === "function") {
        window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID, {
          page_path: url,
        });
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>

      <div className={layout.container}>
        <BaseProvider>
          <DescriptionProvider>
            <AnimatePresence
              initial={false}
              onExitComplete={() => window.scrollTo(0, 0)}
            >
              <Component {...pageProps} />
            </AnimatePresence>
          </DescriptionProvider>
        </BaseProvider>
      </div>
    </>
  );
}

export default MyApp;
