import { BgConfig, ServiceConfig } from "@/interfaces";

export const serviceConfigs: ServiceConfig = {
  twitter: "https://twitter.com/dscribe_me",
};

export const bgConfigs: BgConfig[] = [
  {
    id: "coverImage01",
    src: "/images/bg-01.jpg",
    srcFull: "/images/bg-full-01.jpg",
    alt: "Background Image-1",
  },
  {
    id: "coverImage02",
    src: "/images/bg-02.jpg",
    srcFull: "/images/bg-full-02.jpg",
    alt: "Background Image-2",
  },
  {
    id: "coverImage03",
    src: "/images/bg-03.jpg",
    srcFull: "/images/bg-full-03.jpg",
    alt: "Background Image-3",
  },
  {
    id: "coverImage04",
    src: "/images/bg-04.jpg",
    srcFull: "/images/bg-full-04.jpg",
    alt: "Background Image-4",
  },
  {
    id: "coverImage05",
    src: "/images/bg-05.jpg",
    srcFull: "/images/bg-full-05.jpg",
    alt: "Background Image-5",
  },
  {
    id: "coverImage06",
    src: "/images/bg-06.jpg",
    srcFull: "/images/bg-full-06.jpg",
    alt: "Background Image-6",
  },
];

export const officialUser: string =
  process.env.NEXT_PUBLIC_OFFICIAL_USERNAME || "dscribeme";
export const profileUser: string =
  process.env.NEXT_PUBLIC_PROFILE_USERNAME || "grid_profile";
