import React, { createContext, Dispatch, useReducer } from "react";
import { bgConfigs } from "@/lib/dscribe";

////////////////////////////////////////////
// State
////////////////////////////////////////////
type InitialStateType = {
  coverImageId: string;
  coverImageSrc: string;
  coverImageFullSrc: string;
  showModal: boolean;
  modalType: string | null;
  targetId: string | null;
};

const initialState = {
  coverImageId: "coverImage01",
  coverImageSrc: "/images/bg-01.jpg",
  coverImageFullSrc: "/images/bg-full-01.jpg",
  showModal: false,
  modalType: null,
  targetId: null,
};

////////////////////////////////////////////
// Actions
////////////////////////////////////////////
type ModalStateType = {
  type: string;
};

type TargetModalStateType = {
  modalType: string;
  targetId?: string | null;
};

export type BaseActions =
  | { type: "SHOW_MODAL"; payload: ModalStateType }
  | {
      type: "SHOW_TARGET_MODAL";
      payload: TargetModalStateType;
    }
  | { type: "UPDATE_COVER_IMAGE"; payload: string }
  | { type: "CLOSE_MODAL" };

const baseReducer = (state: InitialStateType, action: BaseActions) => {
  switch (action.type) {
    case "SHOW_MODAL":
      return {
        ...state,
        showModal: true,
        modalType: action.payload.type,
        targetId: null,
      };
    case "SHOW_TARGET_MODAL":
      return {
        ...state,
        showModal: true,
        modalType: action.payload.modalType,
        targetId: action.payload.targetId || null,
      };
    case "CLOSE_MODAL":
      return { ...state, showModal: false, modalType: null, targetId: null };
    case "UPDATE_COVER_IMAGE": {
      const bgConfig =
        bgConfigs.find((config) => config.id === action?.payload) ||
        bgConfigs[0];
      return {
        ...state,
        coverImageId: bgConfig.id,
        coverImageSrc: bgConfig.src,
        coverImageFullSrc: bgConfig.srcFull,
      };
    }
    default:
      return state;
  }
};

////////////////////////////////////////////
// Context
////////////////////////////////////////////
export const BaseStateContext = createContext<InitialStateType>(initialState);

export const BaseDispatchContext = createContext<Dispatch<BaseActions>>(
  () => null
);

export default function BaseProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(baseReducer, initialState);

  return (
    <BaseStateContext.Provider value={state}>
      <BaseDispatchContext.Provider value={dispatch}>
        {children}
      </BaseDispatchContext.Provider>
    </BaseStateContext.Provider>
  );
}
