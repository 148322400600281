import React, { createContext, Dispatch, useReducer } from "react";
import {
  FullDescriptionView,
  OwnerDescriptionView,
  ShortDescriptionView,
} from "@/api/generated";

////////////////////////////////////////////
// State
////////////////////////////////////////////
type InitialStateType = {
  descriptions: (
    | OwnerDescriptionView
    | FullDescriptionView
    | ShortDescriptionView
  )[];
  userDescriptionIds: string[];
  ownerDescriptionId: string;
  ownerDescriptionIds: string[];
  authorDescriptionId: string;
  authorDescriptionIds: string[];
  showPreview: boolean;
  scrollToUser: string;
};

const initialState = {
  descriptions: [],
  userDescriptionIds: [],
  ownerDescriptionId: "",
  ownerDescriptionIds: [],
  authorDescriptionId: "",
  authorDescriptionIds: [],
  showPreview: false,
  scrollToUser: "",
};

////////////////////////////////////////////
// Actions
////////////////////////////////////////////

export type DescriptionActions =
  | {
      type: "INIT_DESCRIPTIONS";
      payload: {
        descriptions: (
          | OwnerDescriptionView
          | FullDescriptionView
          | ShortDescriptionView
        )[];
      };
    }
  | {
      type: "ADD_DESCRIPTIONS";
      payload: {
        descriptions: (
          | OwnerDescriptionView
          | FullDescriptionView
          | ShortDescriptionView
        )[];
      };
    }
  | {
      type: "UPDATE_DESCRIPTION";
      payload: {
        description:
          | OwnerDescriptionView
          | FullDescriptionView
          | ShortDescriptionView;
      };
    }
  | {
      type: "OPEN_PREVIEW";
      payload: {
        descriptionId: string;
      };
    }
  | {
      type: "CLOSE_PREVIEW";
    }
  | {
      type: "SCROLL_TO_USER";
      payload: {
        userId: string;
      };
    }
  | {
      type: "UPDATE_OWNER_DESCRIPTIONS";
      payload: {
        descriptionIds: string[];
      };
    }
  | {
      type: "UPDATE_OWNER_DESCRIPTION";
      payload: {
        descriptionId: string;
      };
    }
  | {
      type: "UPDATE_AUTHOR_DESCRIPTIONS";
      payload: {
        descriptionIds: string[];
      };
    }
  | {
      type: "UPDATE_AUTHOR_DESCRIPTION";
      payload: {
        descriptionId: string;
      };
    };

const descriptionReducer = (
  state: InitialStateType,
  action: DescriptionActions
) => {
  switch (action.type) {
    case "INIT_DESCRIPTIONS":
      return {
        ...state,
        descriptions: action.payload.descriptions,
        userDescriptionIds: action.payload.descriptions.map(
          (description) => description?.id || ""
        ),
      };
    case "ADD_DESCRIPTIONS": {
      const currentDescriptionIds = state.descriptions.map(
        (description) => description.id
      );
      const updatedDescriptions =
        state.descriptions.map((currentDescription) => {
          return (
            action.payload.descriptions.find(
              (d) => d.id === currentDescription.id
            ) || currentDescription
          );
        }) || [];
      const newDescriptions =
        action.payload.descriptions.filter(
          (d) => !currentDescriptionIds.includes(d.id)
        ) || [];
      return {
        ...state,
        descriptions: [...updatedDescriptions, ...newDescriptions],
      };
    }
    case "UPDATE_DESCRIPTION":
      return {
        ...state,
        descriptions: (state.descriptions || []).map((description) => {
          if (description.id === action.payload.description.id) {
            return action.payload.description;
          }

          return description;
        }),
      };
    case "OPEN_PREVIEW":
      return {
        ...state,
        showPreview: true,
        ownerDescriptionId: action.payload.descriptionId,
      };
    case "CLOSE_PREVIEW":
      return {
        ...initialState,
        descriptions: state.descriptions,
        userDescriptionIds: state.userDescriptionIds,
      };
    case "SCROLL_TO_USER":
      return {
        ...state,
        scrollToUser: action.payload.userId,
      };
    case "UPDATE_OWNER_DESCRIPTION":
      return {
        ...state,
        ownerDescriptionId: action.payload.descriptionId,
      };
    case "UPDATE_OWNER_DESCRIPTIONS":
      return {
        ...state,
        ownerDescriptionIds: action.payload.descriptionIds,
      };
    case "UPDATE_AUTHOR_DESCRIPTION":
      return {
        ...state,
        authorDescriptionId: action.payload.descriptionId,
      };
    case "UPDATE_AUTHOR_DESCRIPTIONS":
      return {
        ...state,
        authorDescriptionIds: action.payload.descriptionIds,
      };
    default:
      return state;
  }
};
////////////////////////////////////////////
// Context
////////////////////////////////////////////
export const DescriptionStateContext =
  createContext<InitialStateType>(initialState);

export const DescriptionDispatchContext = createContext<
  Dispatch<DescriptionActions>
>(() => null);

export default function DescriptionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(descriptionReducer, initialState);

  return (
    <DescriptionStateContext.Provider value={state}>
      <DescriptionDispatchContext.Provider value={dispatch}>
        {children}
      </DescriptionDispatchContext.Provider>
    </DescriptionStateContext.Provider>
  );
}
